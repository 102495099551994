<template>
  <nuxt-link
    :to="to || href"
    :data-ux="dataUx"
    class="Link Link-Arrow"
    >{{ text
    }}<HaIcon
      :icon="faArrowRight"
      class="Link Link-ArrowIcon"
      aria-hidden="true"
    />
  </nuxt-link>
</template>

<script setup>
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { HaIcon } from '@ha/components-v3'
</script>

<script>
export default {
  name: 'SeoLink',
  props: {
    to: {
      type: String,
      default: () => ''
    },
    href: {
      type: String,
      default: () => ''
    },
    dataUx: {
      type: String,
      default: () => ''
    },
    text: {
      type: String,
      default: () => ''
    }
  }
}
</script>

<style scoped lang="scss">
.Link {
  font-weight: 600;
  transition: 0.2s;
  color: var(--hads-color-iris);
  white-space: nowrap;
  text-decoration: underline;

  &-ArrowIcon {
    margin-left: 1ch;
  }

  &:hover &-ArrowIcon {
    animation: wiggle 0.3s alternate infinite ease-out;
  }

  @keyframes wiggle {
    to {
      transform: translateX(0.25em);
    }
  }
}
</style>
